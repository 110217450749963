/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Global } from '@emotion/core';

import Header from './header/Header';
import Footer from './footer/Footer';
import './layout.css';


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // set this to `minHeight: '100vh'` for full viewport height
        minHeight: '100vh',
        // minHeight: 256,
      }}
    >
      <Global
        styles={() => ({
          '*': {
            boxSizing: 'border-box',
            '&::before': {
              boxSizing: 'border-box',
            },
            '&::after': {
              boxSizing: 'border-box',
            },
          },
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
          '#___gatsby': {
            height: '100%',
          },
          '#gatsby-focus-wrapper': {},
        })}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
       <br/>
       <br/>
       <br/>
       <br/>
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
